import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

function ItemTitle({ children, to }) {
  return (
      <h3 className="font-semibold leading-snug px-2 -ml-2 rounded-lg hover:bg-yellow-200">
          {children}
      </h3>
  );
}

ItemTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ItemTitle;