import PropTypes from "prop-types";
import React from "react";

function ItemSummary({ children }) {
  return (
    <div className="leading-snug">
        {children}
    </div>
  );
}

ItemSummary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ItemSummary;