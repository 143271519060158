import React from "react";

import Link from "gatsby-link";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Column from "../components/Column";
import Item from "../components/Item";
import ItemTitle from "../components/ItemTitle";
import ItemSummary from "../components/ItemSummary";
import WritingItem from "../components/WritingItem";
import profilePicture from "../images/profile-picture.jpg";

function IndexPage({ data }) {
  const { allMdx } = data; // data.mdx holds your post data
  const articles = allMdx.edges;
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      {/* Wrapper */}
      <div className="max-w-2xl mx-auto text-lg">
        {/* Intro */}
        <div className="mt-16 flex flex-col md:flex-row md:space-x-3 md:items-center">
          <img
            src={profilePicture}
            alt="Profile picture"
            className="rounded-full flex-shrink-0 w-16 h-16"
          />
          <div className="mt-8 md:mt-0 flex flex-col">
            Hi, I&apos;m Dayyan. I&apos;m a software engineer with a focus on
            machine learning.
            <div className="-ml-2">
              <Link
                className="px-2 inline-block text-blue-500 rounded-lg hover:bg-yellow-200"
                to="/about"
              >
                About me →
              </Link>
            </div>
          </div>
        </div>

        {/* Writing and Projects */}
        {/* Writing */}
        <Column columnTitle="Writing">
          {articles.map((article) => (
            <WritingItem key={article.slug} article={article} />
          ))}
          <Link
            className="self-start mt-3 text-blue-500 px-2 -ml-2 rounded-lg hover:bg-yellow-200"
            to="/writing"
          >
            All articles →
          </Link>
        </Column>
        {/* Projects */}
        <Column columnTitle="Projects">
          <Item>
            <a
              href="https://github.com/bahaisongproject"
              className="self-start"
            >
              <ItemTitle>
                bahá&apos;í song project
                <svg
                  className="ml-1 mb-1 inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"></path>
                </svg>
              </ItemTitle>
            </a>

            <ItemSummary>
              200+ Bahá&apos;í-inspired songs with lyrics, chords and videos
            </ItemSummary>
          </Item>
        </Column>
      </div>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    allMdx(limit: 3, sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          slug
          frontmatter {
            title
            description
            date(formatString: "MMMM YYYY")
          }
        }
      }
    }
  }
`;
