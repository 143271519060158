import React from "react";
import Link from "gatsby-link";

import Item from "./Item"
import ItemTitle from "./ItemTitle"
import ItemSummary from "./ItemSummary"
import ItemDate from "./ItemDate"

function WritingItem({ article, key }) {
  return (
    <Item key={key}>
      <Link to={"/" + article.node.slug} className="self-start inline-block" >
        <ItemTitle to={"/" + article.node.slug}>
          {article.node.frontmatter.title}
        </ItemTitle>
      </Link>

      <ItemSummary>{article.node.frontmatter.description}</ItemSummary>
      <ItemDate>{article.node.frontmatter.date}</ItemDate>
    </Item>
  );
}

export default WritingItem;
