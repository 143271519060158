import PropTypes from "prop-types";
import React from "react";

function ItemDate({ children }) {
  return (
    <div className="text-gray-400">
        {children}
    </div>
  );
}

ItemDate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ItemDate;