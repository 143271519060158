import PropTypes from "prop-types";
import React from "react";

function Column({ children, columnTitle }) {
  return (
    <div className="flex-1 mt-20">
      <h2 className="text-2xl font-semibold">{columnTitle}</h2>
      <div className="flex flex-col mt-12 space-y-8">
        {children}
      </div>
  </div>
  );
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Column;