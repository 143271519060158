import PropTypes from "prop-types";
import React from "react";

function Item({ children }) {
  return (
    <div className="flex flex-col space-y-1">
        {children}
    </div>
  );
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Item;